import { LOW_STOCK_BOUNDARIES } from '~/config/shared';
import { LOW_STOCK_LIMITS } from '~/constants/lowStockLimits';
import { PRICE_RANGES, PriceRanges } from '~/constants/priceRanges';
import { countryCodes } from '~/i18n';
import { LowStockBoundary } from '~/types/product/LowStockBoundaries';

function getLowStockBoundary(countryCode: string): LowStockBoundary | undefined {
  return LOW_STOCK_BOUNDARIES[countryCode] ?? LOW_STOCK_BOUNDARIES[countryCodes.SK];
}

function getPriceRange(countryCode: string, price: number): PriceRanges {
  const boundary = getLowStockBoundary(countryCode);
  if (!boundary) {
    return PRICE_RANGES.MID;
  }
  const { highBoundary, lowBoundary } = boundary;

  if (price > highBoundary) {
    return PRICE_RANGES.HIGH;
  } else if (lowBoundary <= price && price <= highBoundary) {
    return PRICE_RANGES.MID;
  } else {
    return PRICE_RANGES.LOW;
  }
}

function getLowStockLimit(priceRange: PriceRanges) {
  return LOW_STOCK_LIMITS[priceRange];
}

export function isLowStock(countryCode: string, price: number, stock: number): boolean {
  const priceRange = getPriceRange(countryCode, price);
  const stockLimit = getLowStockLimit(priceRange);

  if (stock <= stockLimit) {
    return true;
  }

  return false;
}
