




























import { computed, defineComponent } from '@nuxtjs/composition-api';
import {
  useI18n,
  useAddToCartQuantity,
} from '~/composables';
import Button from '~/components/atoms/Buttons/Button/Button.vue';
import isQuickAddToCartRestricted from '~/helpers/Checkout/quickAddToCart/isQuickAddToCartRestricted';

export default defineComponent({
  name: 'AddToCartButton',
  components: {
    Button,
  },
  props: {
    link: {
      type: String,
      required: true,
    },
    sku: {
      type: String,
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
    standardPrice: {
      type: Number,
      required: true,
    },
    isInStock: {
      type: Boolean,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
  },

  setup(props, { root: { $i18n } }) {
    const QUANTITY_TO_ADD = 1;
    const slug = props.link.substring(1);
    const addToCartText = $i18n.t('Add to cart').toString();
    const { addQuantity } = useAddToCartQuantity({
      sku: props.sku,
      slug,
      productPrice: props.standardPrice,
      productName: props.name,
      productImage: props.image,
      isMainProduct: false,
      addToCartText,
    });

    const { countryCode } = useI18n();
    const isRestrictedCountry = computed(() => isQuickAddToCartRestricted(countryCode.value));
    const onAddQuantity = () => {
      addQuantity(QUANTITY_TO_ADD);
    };

    return {
      onAddQuantity,
      slug,
      isRestrictedCountry,
      addToCartText,
    };
  },
});
