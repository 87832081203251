













import { defineComponent } from '@vue/composition-api';
import { LinkType } from '~/types/components/Link/LinkType';
import Link from '~/components/atoms/Link/Link.vue';

export default defineComponent({
  name: 'LowestPriceGuaranteed',
  components: {
    Link,
  },
  props: {
    priceGuaranteeUrl: {
      type: String,
      required: true,
    },
  },
  setup() {
    return { LinkType };
  },
});
