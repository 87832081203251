export const BRAND_NAMES = {
  BREDECO: 'bredeco',
  FROMM_AND_STARCK: 'fromm & starck',
  GOLDBRUNN: 'goldbrunn',
  GYMREX: 'gymrex',
  HILLVERT: 'hillvert',
  INCUBATO: 'incubato',
  MSW: 'msw',
  PHYSA: 'physa',
  ROYAL_CATERING: 'royal catering',
  SINGERCON: 'singercon',
  STAMONY: 'stamony',
  STAMOS: 'stamos',
  STEINBERG: 'steinberg',
  ULSONIX: 'ulsonix',
  UNIPRODO: 'uniprodo',
  WIESENFIELD: 'wiesenfield',
} as const;

export type BrandName = ValueOf<typeof BRAND_NAMES>;

export type BrandInfo = {
  logo: string,
  url?: string,
  name?: string
}

export const BRANDS: Record<BrandName, BrandInfo> = {
  [BRAND_NAMES.BREDECO]: {
    logo: '/brands/Bredeco.svg',
  },
  [BRAND_NAMES.FROMM_AND_STARCK]: {
    logo: '/brands/Fromm_and_Starck.svg',
  },
  [BRAND_NAMES.GOLDBRUNN]: {
    logo: '/brands/Goldbrunn.svg',
  },
  [BRAND_NAMES.GYMREX]: {
    logo: '/brands/Gymrex.svg',
  },
  [BRAND_NAMES.HILLVERT]: {
    logo: '/brands/Hillvert.svg',
  },
  [BRAND_NAMES.INCUBATO]: {
    logo: '/brands/Incubato.svg',
  },
  [BRAND_NAMES.MSW]: {
    logo: '/brands/MSW.svg',
    url: 'https://www.msw24.com',
  },
  [BRAND_NAMES.PHYSA]: {
    logo: '/brands/Physa.svg',
  },
  [BRAND_NAMES.ROYAL_CATERING]: {
    logo: '/brands/Royal_Catering.svg',
    url: 'https://www.cateringroyal.de',
  },
  [BRAND_NAMES.SINGERCON]: {
    logo: '/brands/Singercon.svg',
  },
  [BRAND_NAMES.STAMONY]: {
    logo: '/brands/Stamony.svg',
  },
  [BRAND_NAMES.STAMOS]: {
    logo: '/brands/Stamos.svg',
  },
  [BRAND_NAMES.STEINBERG]: {
    logo: '/brands/Steinberg.svg',
  },
  [BRAND_NAMES.ULSONIX]: {
    logo: '/brands/Ulsonix.svg',
  },
  [BRAND_NAMES.UNIPRODO]: {
    logo: '/brands/Uniprodo.svg',
  },
  [BRAND_NAMES.WIESENFIELD]: {
    logo: '/brands/Wiesenfield.svg',
  },
} as const;

export const BRANDS_WTH_SUBBRANDS: BrandName[] = [
  BRAND_NAMES.STAMOS,
  BRAND_NAMES.STEINBERG,
];

export type BrandsType = ValueOf<typeof BRANDS>;
