













import { defineComponent, ref } from '@nuxtjs/composition-api';
import type { Ref } from '@nuxtjs/composition-api';
import TwicpicsImage from '~/components/atoms/TwicpicsImage/TwicpicsImage.vue';
import { PRODUCT_CARD } from '~/constants/productCard';

export default defineComponent({
  name: 'ProductCardGallery',
  components: {
    TwicpicsImage,
  },
  props: {
    image: {
      type: String,
      required: true,
    },
    altText: {
      type: String,
      default: '',
    },
  },
  setup() {
    const activeIndex: Ref<number> = ref(0);
    return {
      activeIndex,
      productCardImageWidth: PRODUCT_CARD.IMAGE_WIDTH,
      productCardImageHeight: PRODUCT_CARD.IMAGE_HEIGHT,
    };
  },
});
